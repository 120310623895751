$lilypad_green: #3fcf86;

.recentGridRow {
    &:last-child {
        border-bottom: none;
    }
}
.dashboardRecentIcon {
    transform: translateY(-0.1rem);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.noScroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.noScroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.dropShadow {
  box-shadow: 0 15px 40px 0 rgba(0, 0, 0, 0.4), 0 5px 10px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.chakraUIRadio {
  span {
    transform: translateY(1px);
  }
}
@mixin interactive {
  &:hover {
    animation: expand 0.3s ease;
    transform: scale(1.03);
  }
}

@keyframes expand {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.03);
  }
}

.sequenceClip {
  @include interactive;
  border-width: 0px;
  box-sizing: border-box;
}
.sequenceClip.active {
  transition: border-width 0.1s ease;
  outline: 2px solid $lilypad_green;
}

.selected {
  outline: 2px solid green;
  background-color: #22332a !important;

  button {
    background-color: #22332a !important;
  }
  button:hover {
    background-color: #0f1813 !important;
  }
}

.clickMeClickMe {
  animation: waitAnimate_1121779575 3s ease-in-out infinite;
  transform-origin: 50% 50%;
}

@keyframes waitAnimate_1121779575 {
  0% {
    transform: scale(1);
  }
  11% {
    transform: scale(1.1);
  }
  16.667% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  33.333% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

.gridDropZone {
  flex: 1;
  height: 6rem;
}
