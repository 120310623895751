@media screen and (max-width: 80em) {
    .pagination { 
        width: 100%;       
        li a {
            width: calc((100vw - 4rem + -15em)/10);
            text-align: center;
        }
    }
}

@media screen and (max-width: 52em) {
    .pagination li a {
        width: calc((100vw - 3rem)/6);
        text-align: center;
    }
}

@media screen and (max-width: 40em) {
    .pagination li a {
        width: calc((100vw - 2.5rem)/6);
        text-align: center;
    }
}