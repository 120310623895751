.moveable-line {
  width: 0px !important;
}
.moveable-control.moveable-origin {
  display: none !important;
}
.moveable-control {
    // position: absolute;
    width: 6px !important;
    height: 14px !important;
    border-radius: 50px !important;
    // border-top: 0 !important;
    // border-right: 0 !important;
    border: 0 solid #053e24 !important;
    // box-sizing: border-box;
    // background: #3fcf86 !important;
    background: transparent !important;
    margin-top: -11px !important;
    // margin-left: -7px;
    z-index: 5 !important;
  }
  .target {
    position: absolute;
    // width: calc(100% - 8px);
    height:36px;
    margin: 0px;
    border-radius: 5px;
    top: 34px;
    left: 0;
    line-height: 44px;
    text-align: center;
    background: #0755299a;
    opacity: 1;
    border: 3px solid #3fcf86;
    border-right: 1rem solid #3fcf86;
    border-left: 1rem solid #3fcf86;
    color: #333;
    font-weight: bold;
    /* border: 1px solid #333; */
    box-sizing: border-box;
  }