
.loginImgContainer {
    background-image: url('../../public/login/hero3x.png');
    background-position: center;
    background-size: cover;
}
.gradient-wrap {
    padding: 2px !important;
    position: relative !important;
    background: linear-gradient(to right, #3fcf86, #4886e3) !important;
    z-index: 10 !important;
}
.gradient-wrap-disabled {
    background: none !important;
}
.gray-wrap {
    padding: 2px !important;
    position: relative !important;
    background: #d6d6d698 !important;
    z-index: 10 !important;
}
.boldGradientButton {
    height: 2rem !important;
}
